body {
  margin: 50px 50px 100px;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6A6A6A;
  background-color: #FAFAFA;
}

@media only screen and (max-width: 500px) {
  body {
    margin: 50px 20px 50px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
