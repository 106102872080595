.ui.header,
.ui.modal > .header {
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 0px;
}

.ui.form .field > label {
  font-weight: normal;
}
.ui.header:first-child {
  margin-top: 25px !important;
}

.ui.divider:not(.vertical):not(.horizontal):not(hidden) {
  border-top: 1px solid #999;
}

.ui.header.contentHeader {
  margin-top: 40px !important;
}

.sub.header {
  font-size: 13px;
  padding-bottom: 5px;
}

.ui.button {
  color: white;
  background-color: rgba(0, 0, 0, 0.87);
}

.ui.form input[type="checkbox"] {
  vertical-align: 0;
}

input:focus {
  background: white !important;
}

.dateField {
  max-width: 50px;
}

.impressionLabel {
  margin-top: 10px;
}

.promoLabel {
  margin-top: -10px;
  color: #4bbfeb;
  font-weight: bold;
}

.yearField {
  max-width: 65px;
}
.siteGroup {
  margin-top: 10px;
}
.multiHeader {
  color: black;
}
.multiHeader:hover {
  cursor: pointer;
}

.activeMultiHeader {
  font-weight: bold;
}

.paymentSegment {
  width: 50%;
  margin-bottom: 10px !important;
}

.notEnabled {
  color: #6b6b6b;
}

.notEnabled:hover {
  cursor: pointer;
  text-decoration: underline;
}

.accountIdInput {
  margin-top: 14px;
}

.accountIdWorkaround {
  display: none;
}

.logoLink {
  display: contents;
}

.logo {
  display: block;
  margin: auto;
  max-height: 125px;
  max-width: 100%;
}

@media only screen and (max-width: 800px) {
  .paymentSegment {
    width: 100%;
  }
  .previewTool {
    display: block;
    width: 300px;
    margin: auto;
  }
}

.previewTool {
  border: 0;
}

.footer {
  text-align: left;
}

.targetingField {
  height: 19px !important;
  display: inline-block !important;
  margin: 0 7px 4px 0 !important;
}

.targetingNotesWrapper {
  display: inline-block;
  width: 50%
}

.targetingNotesButton:hover {
  cursor: pointer;
}

/* spacing needs work */

.targetingNotesInput {
  display: inline-block !important;
  margin-left: 7px;
  height: 0.5rem;
  width: 75% !important;
}

.targetingNotesInput > input {
  padding: 1px 5px !important;
}

.geoHeaderSpaceFix {
  margin-bottom: 7px !important;
}