.slider {
  position: relative;
  width: 90%;
  height: 80px;
  margin-top: 40px;
  margin-left: 5%;
}

@media only screen and (max-width: 800px) {
  .slider {
    width: 80%;
    margin-left: 10%;
  }

  .handleLabel {
    font-size: 12px !important;
    width: 100px !important;
  }

  .budgetHandleLabel {
    font-size: 12px !important;
    width: 100px !important;
  }
}

.handle {
  position: absolute;
  margin-left: -15px;
  margin-top: 25px;
  z-index: 2;
  width: 30px;
  height: 30px;
  border: 0;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  background-color: white;
  color: #333;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 2px 0px,
    rgba(34, 36, 38, 0.15) 0px 0px 0px 1px inset;
}

.handleLabel {
  margin-top: -40px;
  width: 115px;
  margin-left: -40px;
}

.budgetHandleLabel {
  margin-top: -55px;
  width: 115px;
  margin-left: -40px;
}

.track {
  position: absolute;
  height: 10;
  z-index: 1;
  margin-top: 35;
  opacity: 0.7;
  border-radius: 5;
  cursor: "pointer";
}
